<template>
  <div style="margin-bottom: 30px;">
    <h2>Client nou</h2>
    <a-row class="form-row ant-input-wrapper ant-input-group" :gutter="16" style="margin-bottom: 30px;">
      <a-col :span="18">
        <color-picker v-model="values.color" style="width: 100%" @input="handleColorChange"></color-picker>
      </a-col>
      <a-col :span="6" :style="`margin-top: -5px; height: 54px; background: ${values.color}; border-radius: 5px;`">
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.name" addon-before="Nume complet" class="input-left">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.cnp" addon-before="CNP" class="input-left">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">
              <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
    </a-row>

    <a-row class="form-row" :gutter="16">
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.phone" addon-before="Telefon" class="input-right">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.country" addon-before="Tara" class="input-right" >
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
    </a-row>

    <a-row class="form-row" :gutter="16">
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.county" addon-before="Judet" class="input-left">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.598-.49L10.5.99 5.598.01a.5.5 0 0 0-.196 0l-5 1A.5.5 0 0 0 0 1.5v14a.5.5 0 0 0 .598.49l4.902-.98 4.902.98a.502.502 0 0 0 .196 0l5-1A.5.5 0 0 0 16 14.5V.5zM5 14.09V1.11l.5-.1.5.1v12.98l-.402-.08a.498.498 0 0 0-.196 0L5 14.09zm5 .8V1.91l.402.08a.5.5 0 0 0 .196 0L11 1.91v12.98l-.5.1-.5-.1z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.city" addon-before="Oras" class="input-right">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
    </a-row>

    <a-row class="form-row" :gutter="16">
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.address" addon-before="Adresa" class="input-left">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
      <a-col :span="2"></a-col>
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.fee" addon-before="Tarif / Sedinta" class="input-right" :min="1.00" addon-after="lei" placeholder="20.00">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash" viewBox="0 0 16 16">
              <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
              <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
    </a-row>
    <a-row class="form-row" :gutter="16">
      <a-col :md="12" :xs="24">
        <a-input v-model:value="values.invoiceSeries" addon-before="Serie Factura" class="input-right">
          <template #prefix>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
              <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
            </svg>
          </template>
        </a-input>
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>
    <a-button @click="handleSaveButton" block type="primary" :loading="clientStore.loading" style="margin-top: 20px;">Salveaza</a-button>
    <div v-if="errors.length" style="margin-top: 20px">
      <a-alert v-for="error in errors" :message="error" type="error" show-icon style="margin-bottom: 5px;"/>
    </div>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client-store";
import {Slider} from 'vue-color'
import {getRanHex} from "@/utils";

export default {
  name: 'NewClient',
  components: {
    'color-picker': Slider
  },
  setup () {
    const clientStore = useClientStore()

    return {
      clientStore
    }
  },
  data () {
    return {
      errors: [],
      values: {
        name: null,
        cnp: null,
        phone: null,
        country: 'Romania',
        county: null,
        city: null,
        address: null,
        fee: null,
        color: `#${getRanHex(6)}`,
        invoiceSeries: null,
      },
      names: {
        name: 'Nume',
        cnp: 'CNP',
        phone: 'Telefon',
        country: 'Tara',
        county: 'Judet',
        city: 'Oras',
        address: 'Adresa',
        fee: 'Tarif / sedinta',
        invoiceSeries: 'Serie factura',
      }
    }
  },
  methods: {
    async handleSaveButton () {
      this.checkForm()
      if (!this.errors.length) {
        await this.clientStore.createClient(this.values)
      }
    },
    handleColorChange (value) {
      this.values.color = this.values.color.hex
    },
    checkForm () {
      this.errors = []
      if (Object.values(this.values).every(o => !!o)) {
        return true
      }
      Object.keys(this.values).forEach(key => {
        if (!this.values[key]) {
          this.errors.push(`Campul '${this.names[key]}' nu poate fi gol.`)
        }
      })
    }
  }
}
</script>

<style scoped>
  .input-left {
    margin-bottom: 20px;
  }
  .input-right {
    margin-bottom: 20px;
  }
</style>
